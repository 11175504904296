import React, { useCallback, useState } from 'react';
import { usePopup } from '@/providers/PopupProvider';
import { useRouter } from 'next/router';
import BusinessInfoPopup from '../ui/BusinessInfoPopup';

import EmailPopup from '../ui/EmailPopup';
import queryString from 'query-string';
import { isClient } from '@store/base';

export interface OpenLinkProps {
  url: string;
  target?: '_blank' | '_self';
}

const useFooter = () => {
  const { handleOpen } = usePopup();
  const router = useRouter();
  const [isHover, setIsHover] = useState<boolean>(false);

  const handleOpenPopup = useCallback(() => {
    handleOpen({
      popupProps: {
        title: '이메일 무단수집 거부',
        popupSize: 'sm',
        children: <EmailPopup />,
      },
    });
  }, [handleOpen]);

  const handleOpenBusinessInfoPopup = useCallback(() => {
    handleOpen({
      popupProps: {
        title: '채무지급보증서비스 이용안내',
        popupSize: 'lg',
        children: <BusinessInfoPopup />,
      },
    });
  }, [handleOpen]);

  const handleOpenNewScreen = useCallback(() => {
    if (!isClient) {
      return false;
    }

    window.open(
      'https://www.ftc.go.kr/bizCommPop.do?wrkr_no=1338122865&apv_perm_no=',
      'mypopup',
      'width=600,height=400',
    );
  }, []);

  const handleOpenLink = useCallback(
    ({ url, target = '_self' }: OpenLinkProps) => {
      if (!isClient) {
        return false;
      }

      window.open(url, target);
    },
    [],
  );

  const handleOpenKakaoByMallCenter = useCallback(() => {
    if (!isClient) {
      return false;
    }

    window.open('https://pf.kakao.com/_pMxfxbu');
  }, []);

  const handleOpenKakaoByASCenter = useCallback(() => {
    if (!isClient) {
      return false;
    }

    window.open('https://chat.eumnet.com/api/kakao/chat_open?yid=@hswebas');
  }, []);

  const handleMoveMallCenter = useCallback(() => {
    router.push('https://mall.hanssem.com/csCenter/csMain.do');
  }, [router]);

  const handleMoveServiceCenter = useCallback(() => {
    if (!isClient) {
      return false;
    }

    window.open('https://as.hanssem.com/main.as');
  }, []);

  const handleMoveQuickConsult = useCallback(async () => {
    const channelObj = {
      channelType: 'OPKG_05',
      channelUrl: `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}${router.asPath}`,
    };
    const channelStringified = queryString.stringify(channelObj);
    router.push(`/quick-consult/request?${channelStringified}`);
  }, [router]);

  return {
    handleOpenPopup,
    handleOpenNewScreen,
    handleOpenBusinessInfoPopup,
    handleOpenKakaoByMallCenter,
    handleOpenKakaoByASCenter,
    handleOpenLink,
    handleMoveQuickConsult,
    handleMoveMallCenter,
    handleMoveServiceCenter,
    setIsHover,
    isHover,
  };
};

export default useFooter;
